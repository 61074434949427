import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';
import { DesktopHeaderLayoutA, MobileHeaderLayoutA } from '@src/components/commons';
import { LatestNews } from '@pages-impl/news';

import { newsHeaderProps } from '@pages-impl/news/payload';

import featuredImage from '@images/header-images/featured_image_og_codilime_news.jpg';

export default function NewsTemplate(props) {
  return (
    <Layout {...props} oldPage>
      <SEO
        title="News | CodiLime"
        description="At CodiLime, we like when a lot is going on. Read on to come up to speed."
        featuredImage={featuredImage}
      />
      <DesktopHeaderLayoutA {...newsHeaderProps} />
      <MobileHeaderLayoutA {...newsHeaderProps} />
      <LatestNews data={props.data} />
    </Layout>
  );
}

export const latestNewsQuery = graphql`
  query myquery {
    allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "news" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          layout
          title
          path
          date
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
          thumbnail {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
        }
        excerpt(pruneLength: 325)
      }
    }
  }
`;
