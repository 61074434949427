import React, { useMemo } from 'react';

import { getPostDate } from '@src/utils/calculations';

import { Button } from '@commons/button/Button';
import { Link } from '@commons/link/Link';

import Image from '../image/Image';

import * as styles from './news.module.scss';

export function News({ ...data }) {
  const postDate = useMemo(() => getPostDate(data.frontmatter.date), []);

  return (
    <div className={styles.newsPostContainer}>
      <div className={styles.newsPostImageContainer}>
        <Link href={data.frontmatter.path}>
          <Image className="lazy" image={data.frontmatter.thumbnail} alt={data.frontmatter.title} />
        </Link>
      </div>
      <div className={styles.newsPostContent}>
        <p>{postDate}</p>
        <h3>
          <Link href={data.frontmatter.path}>{data.frontmatter.title}</Link>
        </h3>
        <p className={styles.newsExcerpt}>{data.excerpt}</p>
        <Button href={data.frontmatter.path} label="Read more" />
      </div>
    </div>
  );
}
