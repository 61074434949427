import React, { useMemo, useState } from 'react';

import { Button } from '@commons/button/Button';
import { News } from '@commons/news/News';
import { parseGraphResultToArrayNoEdgesOnlyNews } from '@src/utils/parsers';
import { StandardSection } from '@commons/standard-section/StandardSection';

export const LatestNews = ({ data }) => {
  const latestNewsFinal = useMemo(() => parseGraphResultToArrayNoEdgesOnlyNews(data), []);

  const [howManyNewsPerPage, setHowManyNewsPerPage] = useState(4);
  const [thereAreStillNews, setThereAreStillNews] = useState(true);
  const newsList = latestNewsFinal.slice(0, howManyNewsPerPage).map((value, index) => <News key={index} {...value} />);

  const handleAssigneeOnClick = () => {
    setHowManyNewsPerPage(howManyNewsPerPage + 4);
    newsList.length !== latestNewsFinal.length ? setThereAreStillNews(true) : setThereAreStillNews(false);
  };

  return (
    <StandardSection title="Latest news">
      <div>{newsList}</div>
      {thereAreStillNews && <Button onClick={handleAssigneeOnClick} flexed label="Load more" />}
    </StandardSection>
  );
};
