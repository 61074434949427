import headerImage from './images/news-header.svg';

import * as styles from './payload.module.scss';

export const newsHeaderProps = {
  title: 'Newsroom',
  subtitle: ' News & announcements',
  backgroundImage: headerImage,
  backgroundImageAlt: 'CodiLime news and announcements',
  subtitleBorderWidth: styles.borderWidth,
  classNames: { header: styles.header },
};
